import React, { Fragment, useMemo, useState } from "react"

import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Box, CircularProgress, Grid, Slider } from "@mui/material"
import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosResponse } from "axios"
import { FormikProps } from "formik"
import { isNumber } from "lodash"

import { InfoContainer } from "../../../components/InfoContainer"
import { ConfusionMatrix } from "../../../components/graphs/ConfusionMatrix"
import { ValueLabelComponent } from "../../../screens/ProjectDetails/components/DecisonEngines/components/ModelConfigurations/Components/ModelConfiguration/components/ConfigurationSlider"
import { KonanAPI } from "../../../services/KonanAPI"
import { ConfusionMatrixInput } from "../../../types/custom/projects"

import styles from "../Projects.module.scss"

type Mark = {
  value: number
  label: string
  markLabelStyle: {
    color: string
  }
}

type ParamsType = {
  id: string
}

type Props = {
  modelUUID: string
  isModelDataLoading: boolean
  formik: FormikProps<{ positiveLabel: string; threshold: number; negativeLabel: string; model: string }>
}

export function AuotMLConfigurationForm(props: Props): React.ReactElement {
  const { modelUUID, formik, isModelDataLoading } = props
  const { id: projectUUID } = useParams<ParamsType>()

  const [heatmapData, setHeatmapData] = useState<Array<ConfusionMatrixInput>>([])

  // fetch threshold metrics
  const { data: thresholdMetricsData, isLoading: isThresholdMetricsLoading } = useQuery<AxiosResponse>(
    ["threshold-metrics", modelUUID],
    () =>
      KonanAPI.fetchThresholdWithMetrics({
        projectUUID: projectUUID as string,
        modelUUID: modelUUID as string,
      }),
    {
      enabled: !!projectUUID && !!modelUUID,
      onSuccess: (response) => {
        if (response.data.metrics && response.data.metrics.length > 0) {
          // extracting AutoML model labels dynamically from the return
          // by checking the false values which should always be there
          // then extracting the values without the "false_" prefix
          const autoMlModelLabels = Object.keys(response.data.metrics[0])
            .filter((key) => key.startsWith("false_"))
            .map((key) => key.replace("false_", ""))

          // setting the postive and negative labels to formik arbitrarily by order in the array
          // the user can change them later at will
          formik.setFieldValue("labels", autoMlModelLabels)
          formik.setFieldValue("positiveLabel", autoMlModelLabels[0])
          formik.setFieldValue("negativeLabel", autoMlModelLabels[1])
        }
      },
    },
  )

  const marks: Mark[] = [
    {
      value: 0,
      label: "0",
      markLabelStyle: { color: "var(--grayscale-text-2)" },
    },
    {
      value: 50,
      label: "50",
      markLabelStyle: { color: "var(--grayscale-text-1)" },
    },
    {
      value: thresholdMetricsData?.data?.metrics?.length ? thresholdMetricsData?.data?.metrics?.length - 1 : 99,
      label: `${thresholdMetricsData?.data?.metrics?.length ? thresholdMetricsData?.data?.metrics?.length - 1 : 99}`,
      markLabelStyle: { color: "var(--grayscale-text-2)" },
    },
  ]

  const metricsThresholdMappingMemo = useMemo(() => {
    if (thresholdMetricsData?.data) {
      return thresholdMetricsData?.data?.metrics?.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (metrics: any) => metrics?.threshold?.toFixed(0) === formik?.values?.threshold?.toFixed(0),
      )
    }
  }, [formik?.values?.threshold, thresholdMetricsData?.data])

  const confusionMetricsThresholdMemo = useMemo(() => {
    if (thresholdMetricsData?.data) {
      if (thresholdMetricsData?.data) {
        const threshold = formik?.values?.threshold?.toFixed(0)
        const matchingMetricsData = thresholdMetricsData.data.metrics.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (metrics: any) => metrics.threshold?.toFixed(0) === threshold,
        )
        if (matchingMetricsData) {
          // dynamically extracting metrics data using the labels stored in formik
          const {
            [`false_${formik.values.positiveLabel}`]: false_1,
            [`false_${formik.values.negativeLabel}`]: false_0,
            [`true_${formik.values.positiveLabel}`]: true_1,
            [`true_${formik.values.negativeLabel}`]: true_0,
          } = matchingMetricsData

          if (isNumber(false_1) && isNumber(false_0) && isNumber(true_1) && isNumber(true_0)) {
            const rows = [
              {
                id: "Positive",
                data: [
                  { x: "Positive", y: true_1 },
                  { x: "Negative", y: false_0 },
                ],
              },
              {
                id: "Negative",
                data: [
                  { x: "Positive", y: false_1 },
                  { x: "Negative", y: true_0 },
                ],
              },
            ]
            setHeatmapData(rows)
            return { false_1, false_0, true_1, true_0 }
          }
        }
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik?.values?.threshold,
    formik?.values?.negativeLabel,
    formik?.values?.positiveLabel,
    thresholdMetricsData?.data,
  ])

  return (
    <Grid container direction="column" item xs={12}>
      <Grid item container direction={"column"}>
        <Grid item>
          <Typography variant="label" color="neutral" variantColor={2}>
            Threshold
          </Typography>
        </Grid>

        <Grid item>
          <Slider
            value={formik.values.threshold}
            onChange={(_, value) => {
              formik.setFieldValue("threshold", value)
            }}
            min={0}
            max={thresholdMetricsData?.data?.metrics?.length ? thresholdMetricsData?.data?.metrics?.length - 1 : 99}
            valueLabelDisplay="auto"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            marks={marks}
            componentsProps={{
              markLabel: (props: { marks: Mark[] }) => ({
                style:
                  Array.isArray(props.marks) &&
                  marks.find((mark: Mark, index: number) => mark.label === props?.marks[index].label)?.markLabelStyle,
              }),
            }}
            title="Threshold"
            sx={{
              "& .MuiSlider-thumb": {
                bgcolor: "var(--important-background-default)",
                width: "12px",
                height: "12px",
              },
              "& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover": {
                boxShadow: "none !important",
              },
              "& .MuiSlider-track": {
                bgcolor: "var(--important-border-active)",
              },

              "& .MuiSlider-rail": {
                bgcolor: "var(--neutral-border-default)",
              },
              "& .MuiSlider-mark": {
                display: "none",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item container mt={1} mb={1}>
          <Typography variant="h3-bold">Results</Typography>
        </Grid>
        <Grid container spacing={1}>
          {!formik.values.model ? (
            <Grid minHeight={180} item container alignItems={"center"} justifyContent="center" mt={3}>
              <Typography variant="a">Please choose model to view threshold metrics.</Typography>
            </Grid>
          ) : isThresholdMetricsLoading ? (
            <Grid item container minHeight={180} alignItems={"center"} justifyContent="center" mt={3}>
              <InfoContainer icon={<CircularProgress />} title="Loading Metrics..." />
            </Grid>
          ) : metricsThresholdMappingMemo && Object?.entries(metricsThresholdMappingMemo)?.length > 0 ? (
            <Fragment>
              {Object.entries(metricsThresholdMappingMemo)
                .filter(([key]) => key !== "threshold" && !key.startsWith("false_") && !key.startsWith("true_"))
                .map(([key, value]) => (
                  <Grid item xs={4} md={2} key={key}>
                    <Box
                      className={styles.textualCard}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="p" noWrap style={{ width: "100%" }}>
                        <Tooltip title={key} placement="top" width={"100%"}>
                          <Typography
                            noWrap
                            style={{ paddingLeft: "8px", paddingRight: "8px" }}
                            align="center"
                            variant="p"
                            className={styles.projectDate}
                          >
                            {key}
                          </Typography>
                        </Tooltip>
                      </Typography>

                      <Typography
                        noWrap
                        style={{ width: "100%" }}
                        align="center"
                        variant="p"
                        variantColor={2}
                        color="neutral"
                      >
                        {Number(value)?.toFixed(2) as string}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Fragment>
          ) : (
            !isThresholdMetricsLoading &&
            !isModelDataLoading && (
              <Grid
                xs={12}
                minHeight={180}
                item
                flexGrow={1}
                container
                alignItems={"center"}
                justifyContent="center"
                mt={3}
              >
                <Typography variant="h3-bold">Threshold metrics data unavailable.</Typography>
              </Grid>
            )
          )}
          {modelUUID && (
            <Grid item xs={12} lg={12} mt={3}>
              <ConfusionMatrix
                title="CONFUSION MATRIX"
                data={confusionMetricsThresholdMemo && heatmapData ? heatmapData : []}
                graphHeight={300}
                isLoading={isThresholdMetricsLoading}
                emptyState={{
                  title: !formik?.values?.model
                    ? "Please choose model to view confusion matrix."
                    : !heatmapData || !confusionMetricsThresholdMemo
                      ? "Confusion matrix data unavailable"
                      : "",
                  showGraphs: !heatmapData || !confusionMetricsThresholdMemo ? false : true,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
