import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react"

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Card, CardContent, CardHeader, Grid, IconButton, Button as MuiButton } from "@mui/material"
import { InputText, Menu, MenuItem, Select, Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { FieldArray, FormikProps, FormikProvider } from "formik"
import { v4 as uuidv4 } from "uuid"

import { BaseSimpleDialog } from "../../components/dialogs/BaseSimpleDialog"
import { getTheme } from "../../hooks/UseTheme"
import { Condition } from "../../screens/ProjectDetails/components/DecisonEngines/components/Ruleset/components/components/Condition"
import { Operators } from "../../types/custom/projects"
import { Rule, TagCardFormikValues, TagGroupConditions } from "../../types/custom/rules"
import { ConditionListFile } from "../../types/generated/api/ConditionListFile"
import { WorkflowSchemaFeature } from "../../types/generated/api/WorkflowSchemaFeature"

import styles from "../../screens/ProjectDetails/components/DecisonEngines/components/Ruleset/components/components/RuleCard.module.scss"

/**
 * Helper UI component to render data items in taglist card
 * @param {string} value
 * @return {React.ReactElement}
 */
export function DataBlock(props: Readonly<{ value: string; isLoading?: boolean }>): React.ReactElement {
  const { value, isLoading } = props

  return (
    <div className={styles.cardField}>
      <Typography variant="h3-bold">
        {isLoading ? <Skeleton variant="rectangular" width={100} height={20} /> : value}
      </Typography>
    </div>
  )
}

type Props = {
  editMode?: boolean
  createMode?: boolean
  name: string
  condition?: Rule[][]
  conditionListFile?: ConditionListFile | null
  taglistFormik: FormikProps<TagCardFormikValues>
  isLoading?: boolean
  index: number
  andOr: ["and", "or"]
  dialogView?: boolean
  handleDelete?: (tagName: string, stateFn: Dispatch<SetStateAction<boolean>>) => void
  handleRemoveRule?: (index: number) => boolean
}

/**
 * Rule card component
 * @return {React.ReactElement}
 */
export function TagCard(props: Readonly<Props>): React.ReactElement {
  const {
    editMode = false,
    createMode = false,
    name,
    condition,
    handleDelete,
    handleRemoveRule,
    conditionListFile,
    isLoading = false,
    index,
    andOr,
    taglistFormik: formik,
    dialogView,
  } = props

  const theme = getTheme()

  const [openRuleDeletionDialog, setOpenRuleDeletionDialog] = useState<boolean>(false)

  const uniqueId = uuidv4()

  const emptyCondition = {
    id: uniqueId,
    feature: "",
    andOr: "and",
    operator: Operators["="],
    type: "string",
    value: "",
    secondValue: "",
    valueOrFeature: "Value",
    schemaFeatureType: WorkflowSchemaFeature.type.TEXT,
  }

  // specific object to view rule card in version history (read-only mode)
  const singleRuleCard = {
    tagName: createMode ? "" : name,
    levelOneConditions: condition && condition.length > 0 ? condition[0] : [emptyCondition],
    levelTwoConditions: condition && condition.length > 1 ? condition[1] : [],
    levelThreeConditions: condition && condition.length > 2 ? condition[2] : [],
    condition_list_file: { name: "", uuid: "" },
  }

  // anchor for settings menu for tag card
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  // anchor for add rule menu (lvl1)
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null)
  // anchor for add rule menu (lvl2)
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)
  // anchor for add rule menu (lvl3)
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null)
  const openMenu1 = Boolean(anchorEl1)
  const openMenu2 = Boolean(anchorEl2)
  const openMenu3 = Boolean(anchorEl3)

  // this state tracks the number of uploaded/referenced files in a rule
  const [filesCountInRule, setFilesCountInRule] = useState<number>(0)

  const [fileData, setFileData] = useState<{ name: string; uuid: string }>({ name: "", uuid: "" })

  const openRuleCardMenu = Boolean(anchor)

  const updateFileCount = (shouldRemoveFile: boolean): void => {
    /**
     * this removeCount variable handles the edge case where if you removed the only condition in
     * levelTwoConditions and levelThreeConditions also have conditions, the delete count of removed files should equal
     * the length of -> (levelThreeConditions + 1), otherwise the count will be set to 1
     * */
    const removeCount =
      formik?.values?.tagCards[index]?.levelTwoConditions?.length === 1 &&
      formik?.values?.tagCards[index]?.levelThreeConditions?.length > 0
        ? formik?.values?.tagCards[index]?.levelThreeConditions?.length + 1
        : 1
    if (shouldRemoveFile) {
      // only remove the condition_list_file from rule, if we removed the file in this conditions
      // and filesCountInRule was equal to only one
      if (filesCountInRule === 1) {
        formik.setFieldValue(`tagCards[${index}].condition_list_file`, { name: "", uuid: "" })
      }

      setFilesCountInRule((prev) => (prev - removeCount < 0 ? 0 : prev - removeCount))
    }
  }

  const handleRemoveCondition = (
    levelRemove: (idx: number) => void,
    conditionIdx: number,
    conditionLevel: "levelThreeConditions" | "levelTwoConditions",
  ): void => {
    // current level the condition being removed from
    const currentLevelLength = formik?.values?.tagCards[index]?.[conditionLevel]?.length

    // the migrated level/group -> ex: if removing condition from levelTwo then the migrated level is levelOneConditions
    const levelToBeMigratedTo = conditionLevel === "levelThreeConditions" ? "levelTwoConditions" : "levelOneConditions"

    // the condition index that will be migarted to the prev level
    const conditionIndexToBeMigrated = conditionIdx === 0 ? 1 : 0

    if (currentLevelLength <= 2) {
      // cache the migrated condition in a temp variable
      const tempCondition = formik?.values?.tagCards[index]?.[conditionLevel][conditionIndexToBeMigrated]

      // remove the whole level
      formik?.setFieldValue(`tagCards[${index}].${conditionLevel}`, [])

      // push the migrated condition to the prev/migrated level
      formik?.setFieldValue(`tagCards[${index}].${levelToBeMigratedTo}`, [
        ...(formik?.values?.tagCards[index]?.[levelToBeMigratedTo] ?? []),
        tempCondition,
      ])

      // edge case, removing levelTwoConditions while levelThreeConditions still has data
      if (
        conditionLevel === "levelTwoConditions" &&
        formik?.values?.tagCards[index]?.levelThreeConditions?.length > 0
      ) {
        // assign levelTwoConditions data to be equal to levelThreeConditions
        formik?.setFieldValue(`tagCards[${index}].levelTwoConditions`, [
          ...(formik?.values?.tagCards[index]?.levelThreeConditions ?? []),
        ])

        // and remove levelThreeConditions data
        formik?.setFieldValue(`tagCards[${index}].levelThreeConditions`, [])
      }
    } else {
      levelRemove(conditionIdx)
    }
  }

  // setting the "filesCountInRule" state to the number of "$file" tokens found in the 3 levels of conditions
  useEffect(() => {
    // resetting count before recounting the number of files occurrences in rule
    setFilesCountInRule(0)
    if (editMode) {
      formik.values.tagCards.forEach((tagCard) => {
        if (tagCard.levelOneConditions && tagCard.levelOneConditions.length > 0) {
          tagCard.levelOneConditions.forEach((item: Rule) => {
            if (item?.value === "$file") {
              setFilesCountInRule((prev) => prev + 1)
            }
          })
        }

        if (tagCard.levelTwoConditions && tagCard.levelTwoConditions.length > 0) {
          tagCard.levelTwoConditions.forEach((item: Rule) => {
            if (item?.value === "$file") {
              setFilesCountInRule((prev) => prev + 1)
            }
          })
        }

        if (tagCard.levelThreeConditions && tagCard.levelThreeConditions.length > 1) {
          tagCard.levelThreeConditions.forEach((item: Rule) => {
            if (item?.value === "$file") {
              setFilesCountInRule((prev) => prev + 1)
            }
          })
        }
      })
    } else {
      setFilesCountInRule(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  return (
    <Card className={styles.card}>
      {openRuleDeletionDialog && (
        <BaseSimpleDialog
          open={openRuleDeletionDialog}
          name={name}
          onClose={() => setOpenRuleDeletionDialog(false)}
          onAccept={() => {
            if (handleDelete) {
              handleDelete(formik?.values?.tagCards[index]?.tagName, setOpenRuleDeletionDialog)
            }
          }}
          mode={"rule-deletion"}
          isLoading={isLoading}
        />
      )}

      <CardHeader
        className={styles.cardHeader}
        title={
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              {editMode || createMode ? (
                <InputText
                  hideDescription
                  id={`tagCards[${index}].tagName`}
                  placeholder="Name"
                  value={formik.values.tagCards[index]?.tagName}
                  handleChange={formik.handleChange}
                  error={
                    formik?.touched?.tagCards &&
                    formik?.errors?.tagCards &&
                    formik?.touched?.tagCards[index]?.tagName &&
                    Boolean((formik?.errors?.tagCards[index] as TagGroupConditions)?.tagName) &&
                    (formik?.errors?.tagCards[index] as TagGroupConditions)?.tagName
                  }
                  handleBlur={formik.handleBlur}
                  disabled={formik?.isSubmitting || isLoading}
                  description="Manually configured condition that returns a decision when matched."
                  fullWidth
                />
              ) : (
                <Typography variant="h3-bold" style={{ textTransform: "inherit" }}>
                  {name}
                </Typography>
              )}
            </Grid>
          </Grid>
        }
        action={
          handleDelete &&
          handleRemoveRule && (
            <Fragment>
              <IconButton
                aria-label="settings"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget)}
              >
                <MoreHorizIcon htmlColor={theme.palette.grayscale.text[1]} />
              </IconButton>
              <Menu
                key="basic-menu"
                anchorEl={anchor}
                open={openRuleCardMenu}
                onClose={() => setAnchor(null)}
                menuMaxContent
              >
                <Tooltip
                  width={"100%"}
                  title={1 === formik?.values?.tagCards?.length && "Taglist should have at least one tag"}
                >
                  <MenuItem
                    onClick={() => {
                      const removeIndicator = handleRemoveRule?.(index)
                      if (removeIndicator) {
                        setOpenRuleDeletionDialog(true)
                      }
                      setAnchor(null)
                    }}
                    disabled={formik?.values?.tagCards?.length === 1 || isLoading}
                  >
                    <Typography variant="a" color="negative" variantColor={2}>
                      Remove Tag
                    </Typography>
                  </MenuItem>
                </Tooltip>
              </Menu>
            </Fragment>
          )
        }
      />

      <CardContent className={styles.cardContent}>
        {dialogView && condition ? (
          <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
            <Grid container item spacing={1}>
              {singleRuleCard?.levelOneConditions?.map((item: Rule, idx: number) => (
                <Grid item xs={12} key={item.id}>
                  <Condition
                    key={item.id}
                    formik={formik}
                    conditionLevel={"levelOneConditions"}
                    id={`levelOneConditions[${idx}]`}
                    index={idx}
                    editMode={false}
                    createMode={false}
                    versionDialogCondition={singleRuleCard?.levelOneConditions[idx]}
                    ruleIndex={index}
                    conditionListFile={conditionListFile}
                    groupSwitch={formik.values.tagCards[index].group1Switch}
                    lastSwitchSetter={(data) =>
                      formik.setFieldValue(`formik.values.tagCards[${index}].group1Switch`, data)
                    }
                    setFileData={setFileData}
                    fileData={fileData}
                    filesCount={filesCountInRule}
                    setFilesCount={setFilesCountInRule}
                  />
                </Grid>
              ))}
              {/* and/or */}
              {singleRuleCard.levelTwoConditions && singleRuleCard.levelTwoConditions.length > 0 && (
                <Grid item xs={3} md={2} lg={1}>
                  <DataBlock
                    value={
                      singleRuleCard.levelOneConditions.length > 0 ? singleRuleCard.levelOneConditions[0].andOr : ""
                    }
                  />
                </Grid>
              )}

              {singleRuleCard.levelTwoConditions.length > 0 && (
                <Grid item xs>
                  <Grid
                    container
                    item
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    className={styles.groupBackground}
                  >
                    <Grid container item spacing={1}>
                      {singleRuleCard.levelTwoConditions?.map((item: Rule, idx: number) => (
                        <Grid item xs={12} key={item.id}>
                          <Condition
                            key={item.id}
                            versionDialogCondition={singleRuleCard?.levelTwoConditions[idx]}
                            ruleIndex={index}
                            conditionLevel={"levelTwoConditions"}
                            id={`levelTwoConditions[${idx}]`}
                            conditionListFile={conditionListFile as ConditionListFile}
                            index={idx}
                            editMode={false}
                            createMode={false}
                            formik={formik}
                            groupSwitch={formik.values.tagCards[index].group2Switch}
                            lastSwitchSetter={(data) =>
                              formik.setFieldValue(`formik.values.tagCards[${index}].group2Switch`, data)
                            }
                            setFileData={setFileData}
                            fileData={fileData}
                            filesCount={filesCountInRule}
                            setFilesCount={setFilesCountInRule}
                          />
                        </Grid>
                      ))}

                      {/* and/or */}
                      {singleRuleCard.levelThreeConditions && singleRuleCard.levelThreeConditions.length > 0 && (
                        <Grid item xs={3} md={2} lg={1}>
                          <DataBlock
                            value={
                              singleRuleCard.levelTwoConditions && singleRuleCard.levelTwoConditions.length > 0
                                ? singleRuleCard.levelTwoConditions[0].andOr
                                : ""
                            }
                          />
                        </Grid>
                      )}

                      {/* Level 3 group */}
                      {singleRuleCard.levelThreeConditions.length > 0 && (
                        <Grid item xs>
                          <Grid container item className={styles.groupBackground}>
                            <Grid container item justifyContent="flex-end" alignItems="center" spacing={1}>
                              {singleRuleCard?.levelThreeConditions?.map((item: Rule, idx: number) => (
                                <Grid item xs={12} key={item.id}>
                                  <Condition
                                    key={item.id}
                                    versionDialogCondition={singleRuleCard?.levelThreeConditions[idx]}
                                    ruleIndex={index}
                                    conditionLevel={"levelThreeConditions"}
                                    id={`levelThreeConditions[${idx}]`}
                                    conditionListFile={conditionListFile as ConditionListFile}
                                    index={idx}
                                    editMode={false}
                                    createMode={false}
                                    formik={formik}
                                    setFileData={setFileData}
                                    fileData={fileData}
                                    filesCount={filesCountInRule}
                                    setFilesCount={setFilesCountInRule}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <FormikProvider value={formik}>
            {/* Level 1 group */}
            <FieldArray name={`tagCards[${index}].levelOneConditions`}>
              {({ push: level1push, remove: level1remove }) => (
                <Grid container justifyContent="flex-end" alignItems="center" className={styles.groupBackground}>
                  <Grid container item spacing={1}>
                    {formik?.values?.tagCards[index]?.levelOneConditions?.map((item: Rule, idx: number) => (
                      <Grid item xs={12} key={item.id}>
                        <Condition
                          key={item.id}
                          isTag={true}
                          conditionLevel={"levelOneConditions"}
                          id={`tagCards[${index}].levelOneConditions[${idx}]`}
                          index={idx}
                          editMode={editMode}
                          createMode={createMode}
                          ruleIndex={index}
                          fileUUID={conditionListFile?.uuid}
                          formik={formik}
                          removeCondition={(shouldRemoveFile: boolean) => {
                            updateFileCount(shouldRemoveFile)
                            level1remove(idx)
                          }}
                          groupSwitch={formik.values.tagCards[index].group1Switch}
                          lastSwitchSetter={(data) => formik.setFieldValue(`tagCards[${index}].group1Switch`, data)}
                          setFileData={setFileData}
                          fileData={fileData}
                          filesCount={filesCountInRule}
                          setFilesCount={setFilesCountInRule}
                        />
                      </Grid>
                    ))}

                    {/* and/or */}
                    {formik.values.tagCards[index]?.levelTwoConditions &&
                      formik.values.tagCards[index]?.levelTwoConditions.length > 0 && (
                        <Grid item xs={3} md={2} lg={1}>
                          {editMode && formik.values.tagCards[index]?.levelOneConditions.length === 1 ? (
                            <Select
                              hideDescription
                              type="text"
                              value={formik.values.tagCards[index].group1Switch}
                              handleChange={(e) =>
                                formik.setFieldValue(`tagCards[${index}].group1Switch`, e.target.value as string)
                              }
                              fullWidth
                              disabled={formik.values.tagCards[index]?.levelOneConditions.length > 1}
                              options={andOr}
                              menuProps={{ menuMaxContent: true }}
                            />
                          ) : (
                            <DataBlock
                              value={
                                editMode
                                  ? formik.values.tagCards[index].group1Switch
                                  : formik.values.tagCards[index]?.levelOneConditions &&
                                      formik.values.tagCards[index]?.levelOneConditions.length > 0
                                    ? formik.values.tagCards[index]?.levelOneConditions[0].andOr
                                    : ""
                              }
                            />
                          )}
                        </Grid>
                      )}

                    {/* Level 2 group */}
                    {formik.values.tagCards[index]?.levelTwoConditions &&
                      formik.values.tagCards[index]?.levelTwoConditions.length > 0 && (
                        <Grid item xs>
                          <FieldArray name={`tagCards[${index}].levelTwoConditions`}>
                            {({ push: level2push, remove: level2remove }) => (
                              <Grid
                                container
                                item
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                className={styles.groupBackground}
                              >
                                <Grid container item spacing={1}>
                                  {formik.values.tagCards[index]?.levelTwoConditions?.map((item: Rule, idx: number) => (
                                    <Grid item xs={12} key={item.id}>
                                      <Condition
                                        key={item.id}
                                        isTag={true}
                                        ruleIndex={index}
                                        conditionLevel={"levelTwoConditions"}
                                        id={`tagCards[${index}].levelTwoConditions[${idx}]`}
                                        conditionListFile={conditionListFile}
                                        index={idx}
                                        editMode={editMode}
                                        createMode={createMode}
                                        formik={formik}
                                        removeCondition={(shouldRemoveFile: boolean) => {
                                          updateFileCount(shouldRemoveFile)
                                          handleRemoveCondition(level2remove, idx, "levelTwoConditions")
                                        }}
                                        groupSwitch={formik.values.tagCards[index].group2Switch}
                                        lastSwitchSetter={(data) =>
                                          formik.setFieldValue(`tagCards[${index}].group2Switch`, data)
                                        }
                                        setFileData={setFileData}
                                        fileData={fileData}
                                        filesCount={filesCountInRule}
                                        setFilesCount={setFilesCountInRule}
                                      />
                                    </Grid>
                                  ))}

                                  {/* and/or */}
                                  {formik.values.tagCards[index]?.levelThreeConditions &&
                                    formik.values.tagCards[index]?.levelThreeConditions.length > 0 && (
                                      <Grid item xs={3} md={2} lg={1}>
                                        {editMode && formik.values.tagCards[index]?.levelTwoConditions.length === 1 ? (
                                          <Select
                                            hideDescription
                                            type="text"
                                            fullWidth
                                            value={formik.values.tagCards[index].group2Switch}
                                            handleChange={(e) =>
                                              formik.setFieldValue(
                                                `tagCards[${index}].group2Switch`,
                                                e.target.value as string,
                                              )
                                            }
                                            disabled={formik.values.tagCards[index]?.levelTwoConditions.length > 1}
                                            options={andOr}
                                            menuProps={{ menuMaxContent: true }}
                                          />
                                        ) : (
                                          <DataBlock
                                            value={
                                              editMode
                                                ? formik.values.tagCards[index].group2Switch
                                                : formik.values.tagCards[index]?.levelTwoConditions &&
                                                    formik.values.tagCards[index]?.levelTwoConditions.length > 0
                                                  ? formik.values.tagCards[index]?.levelTwoConditions[0].andOr
                                                  : ""
                                            }
                                          />
                                        )}
                                      </Grid>
                                    )}

                                  {/* Level 3 group */}
                                  {formik.values.tagCards[index]?.levelThreeConditions &&
                                    formik.values.tagCards[index]?.levelThreeConditions.length > 0 && (
                                      <Grid item xs>
                                        <FieldArray name={`tagCards[${index}].levelThreeConditions`}>
                                          {({ push: level3push, remove: level3remove }) => (
                                            <Grid container item className={styles.groupBackground}>
                                              <Grid
                                                container
                                                item
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={1}
                                              >
                                                {formik.values.tagCards[index]?.levelThreeConditions?.map(
                                                  (item: Rule, idx: number) => (
                                                    <Grid item xs={12} key={item.id}>
                                                      <Condition
                                                        key={item.id}
                                                        isTag={true}
                                                        ruleIndex={index}
                                                        conditionLevel={"levelThreeConditions"}
                                                        id={`tagCards[${index}].levelThreeConditions[${idx}]`}
                                                        conditionListFile={conditionListFile}
                                                        index={idx}
                                                        editMode={editMode}
                                                        createMode={createMode}
                                                        formik={formik}
                                                        removeCondition={(shouldRemoveFile: boolean) => {
                                                          updateFileCount(shouldRemoveFile)
                                                          handleRemoveCondition(
                                                            level3remove,
                                                            idx,
                                                            "levelThreeConditions",
                                                          )
                                                        }}
                                                        setFileData={setFileData}
                                                        fileData={fileData}
                                                        filesCount={filesCountInRule}
                                                        setFilesCount={setFilesCountInRule}
                                                      />
                                                    </Grid>
                                                  ),
                                                )}
                                              </Grid>

                                              {/* level 3 menu button  */}
                                              {(editMode || createMode) &&
                                                formik.values.tagCards[index]?.levelThreeConditions &&
                                                formik.values.tagCards[index]?.levelThreeConditions.length > 0 && (
                                                  <Grid container>
                                                    <Grid item lg={12} mt={1}>
                                                      {/* Todo: Should be replaced later on once we have text buttons in Sui */}
                                                      <MuiButton
                                                        size="small"
                                                        className={styles.MuiButton}
                                                        onClick={(event) => setAnchorEl3(event.currentTarget)}
                                                        disabled={isLoading || formik.isSubmitting}
                                                      >
                                                        + Add Rule
                                                      </MuiButton>
                                                      <Menu
                                                        key="basic-menu3"
                                                        anchorEl={anchorEl3}
                                                        open={openMenu3}
                                                        onClose={() => setAnchorEl3(null)}
                                                        menuMaxContent
                                                      >
                                                        <MenuItem
                                                          onClick={() => {
                                                            level3push(emptyCondition)
                                                            setAnchorEl3(null)
                                                          }}
                                                        >
                                                          <Grid container display={"flex"} gap={1} height={"20px"}>
                                                            <Grid item>
                                                              <AddOutlinedIcon
                                                                fontSize="small"
                                                                htmlColor={theme.palette.grayscale.text[1]}
                                                              />
                                                            </Grid>

                                                            <Grid item>
                                                              <Typography variant="a">Add Rule</Typography>
                                                            </Grid>
                                                          </Grid>
                                                        </MenuItem>
                                                      </Menu>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                            </Grid>
                                          )}
                                        </FieldArray>
                                      </Grid>
                                    )}

                                  {/* level 2 menu button  */}
                                  {(editMode || createMode) &&
                                    formik.values.tagCards[index]?.levelTwoConditions &&
                                    formik.values.tagCards[index]?.levelTwoConditions.length > 0 && (
                                      <Grid container>
                                        <Grid item lg={12} mt={1}>
                                          {/* Todo: Should be replaced later on once we have text buttons in Sui  */}
                                          <MuiButton
                                            size="small"
                                            className={styles.MuiButton}
                                            onClick={(event) => setAnchorEl2(event.currentTarget)}
                                            disabled={isLoading || formik.isSubmitting}
                                          >
                                            + Add Rule
                                          </MuiButton>
                                          <Menu
                                            key="basic-menu2"
                                            anchorEl={anchorEl2}
                                            open={openMenu2}
                                            onClose={() => setAnchorEl2(null)}
                                            menuMaxContent
                                          >
                                            <MenuItem
                                              onClick={() => {
                                                level2push({
                                                  ...emptyCondition,
                                                  andOr: formik.values.tagCards[index].group2Switch,
                                                })
                                                setAnchorEl2(null)
                                              }}
                                            >
                                              <Grid container display={"flex"} gap={1} height={"20px"}>
                                                <Grid item>
                                                  <AddOutlinedIcon
                                                    fontSize="small"
                                                    htmlColor={theme.palette.grayscale.text[1]}
                                                  />
                                                </Grid>

                                                <Grid item>
                                                  <Typography variant="a">Add Rule</Typography>
                                                </Grid>
                                              </Grid>
                                            </MenuItem>
                                            {formik.values.tagCards[index]?.levelThreeConditions &&
                                              formik.values.tagCards[index]?.levelThreeConditions.length === 0 && (
                                                <MenuItem
                                                  onClick={() => {
                                                    formik.values.tagCards[index]?.levelThreeConditions.push(
                                                      emptyCondition,
                                                      emptyCondition,
                                                    )
                                                    setAnchorEl2(null)
                                                  }}
                                                >
                                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                                    <Grid item>
                                                      <AddBoxOutlinedIcon
                                                        fontSize="small"
                                                        htmlColor={theme.palette.grayscale.text[1]}
                                                      />
                                                    </Grid>
                                                    <Grid item>
                                                      <Typography variant="a">Add Group</Typography>
                                                    </Grid>
                                                  </Grid>
                                                </MenuItem>
                                              )}
                                          </Menu>
                                        </Grid>
                                      </Grid>
                                    )}
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                        </Grid>
                      )}

                    {/* level 1 menu button  */}
                    {(editMode || createMode) && (
                      <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item lg={12} mt={1}>
                          {/* Todo: Should be replaced later on once we have text buttons in Sui  */}
                          <MuiButton
                            size="small"
                            className={styles.MuiButton}
                            onClick={(event) => setAnchorEl1(event.currentTarget)}
                            disabled={isLoading || formik.isSubmitting}
                          >
                            + Add Rule
                          </MuiButton>
                          <Menu
                            key="basic-menu1"
                            anchorEl={anchorEl1}
                            open={openMenu1}
                            onClose={() => setAnchorEl1(null)}
                            menuMaxContent
                          >
                            <MenuItem
                              onClick={() => {
                                level1push({ ...emptyCondition, andOr: formik.values.tagCards[index].group1Switch })
                                setAnchorEl1(null)
                              }}
                            >
                              <Grid container display={"flex"} gap={1} height={"20px"}>
                                <Grid item>
                                  <AddOutlinedIcon fontSize="small" htmlColor={theme.palette.grayscale.text[1]} />
                                </Grid>
                                <Grid item>
                                  <Typography variant="a">Add Rule</Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                            {formik.values.tagCards[index]?.levelTwoConditions &&
                              formik.values.tagCards[index]?.levelTwoConditions.length === 0 && (
                                <MenuItem
                                  onClick={() => {
                                    formik.values.tagCards[index]?.levelTwoConditions.push(
                                      emptyCondition,
                                      emptyCondition,
                                    )
                                    setAnchorEl1(null)
                                  }}
                                >
                                  <Grid container display={"flex"} gap={1} height={"20px"}>
                                    <Grid item>
                                      <AddBoxOutlinedIcon
                                        fontSize="small"
                                        htmlColor={theme.palette.grayscale.text[1]}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="a">Add Group</Typography>
                                    </Grid>
                                  </Grid>
                                </MenuItem>
                              )}
                          </Menu>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </FieldArray>
          </FormikProvider>
        )}
      </CardContent>
    </Card>
  )
}
