import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react"

import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Box, Card, CircularProgress, Grid, Link } from "@mui/material"
import { InputText, Snackbar, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import _ from "lodash"
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table"

import { CurrentProjectAndModelContext } from "../../store/CurrentProjectAndModelContext"
import { ExportJobCreateRequest } from "../../types/generated/api/ExportJobCreateRequest"
import { PaginatedWorkflowSimulationOutputList } from "../../types/generated/api/PaginatedWorkflowSimulationOutputList"
import { WorkflowSimulationOutput } from "../../types/generated/api/WorkflowSimulationOutput"
import { keyValueDeepJsonify } from "../../utils/modelDetailsHelpers"
import { KonanJsonView } from "../KonanJsonView"
import { ExportDataDialog } from "../dialogs/ExportDataDialog"
import { BaseTableContainer } from "./BaseTableContainer"
import { TAG_VARIANTS } from "./CONSTANTS"
import { CustomTableHeaders, CustomTableHeadersWithInternalTooltip } from "./CustomTableHeader"
import { FeedbackPopper } from "./ProjectDecisions"

import styles from "./ProjectDecisions.module.scss"

const tagVariants = [...TAG_VARIANTS]

const tagVariantHash = {}

type Props = {
  uuid: string
  data: PaginatedWorkflowSimulationOutputList | undefined
  isFetching: boolean
  isLoading: boolean
  pagination: MRT_PaginationState
  setPagination?: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  filterObject: object
  setFilterObject: React.Dispatch<React.SetStateAction<object>>
}

// TODO:: refator this and the projectDecisions table to be the same table (...almost)
export function SimulationOutputTable(props: Readonly<Props>): React.ReactElement {
  const { uuid, data, isFetching, isLoading, pagination, setPagination, filterObject, setFilterObject } = props

  const { currentProject } = useContext(CurrentProjectAndModelContext)

  const [isExportDialogOpen, setIsExportDialogOpen] = useState<boolean>(false)

  const clearFilter = useCallback(
    (item: string, type: string): void => {
      const tempObject = filterObject
      // eslint-disable-next-line
      // @ts-ignore
      delete tempObject[`${type}__${item.split(".").join("__")}`]

      setFilterObject({
        ...tempObject,
      })
      setPagination?.({
        pageIndex: 0,
        pageSize: 10,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterObject],
  )

  const handleFilterChange = useCallback(
    (value: string | number, title: string, type: string): void => {
      const tempObject = filterObject

      if (!value) {
        clearFilter(title, type)
        return
      }

      // eslint-disable-next-line
      // @ts-ignore
      tempObject[`${type}__${title.split(".").join("__")}`] = value

      setFilterObject({
        ...tempObject,
      })
      setPagination?.({
        pageIndex: 0,
        pageSize: 10,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterObject],
  )

  const debouncedFilter = useMemo(() => {
    return _.debounce(handleFilterChange, 500)
  }, [handleFilterChange])

  // When the component unmounts, the search request could be still in progress.
  //  In this case, an error will occur when the characters state is set.
  useEffect(() => {
    return () => {
      debouncedFilter.cancel()
    }
  }, [debouncedFilter])

  const assignTagVariantToReasonValue = (reason: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tagVariantHash[reason] = tagVariants[0]
    tagVariants.splice(0, 1)
  }

  const decisionReason = (rowData: WorkflowSimulationOutput): { text: string; tooltip: string; type: string } => {
    const tooltip = rowData.output?.description
    let text = ""
    let type = currentProject?.category === "workflow" ? "__" : "AI Engine"

    if (rowData.output) {
      // getting last node in the satisfied_flow_path
      // to show it's data in the reason column since it's the last node the prediction ran into
      const lastNode = rowData.output?.satisfied_flow_path?.slice(-1)[0]
      type = lastNode?.type
      if (lastNode?.type !== "project") {
        // Adding __ as a fallback for when there isn't any nodes in the satisfied_flow_path to parse the data from
        text = lastNode ? `${lastNode?.type?.charAt(0).toUpperCase() + lastNode?.type?.slice(1)}` : "__"
      }

      if (lastNode?.name) type += `: ${lastNode.name}`
      if (lastNode?.name) text = `${lastNode.name}`
    }

    return { text: text, type: type, tooltip: tooltip }
  }

  //get table columns from response
  const tableColumns = useMemo((): { title: string; field: string; type: string }[] => {
    const columnSet = new Set()

    // loop over the list to collect all possible columns
    if (data?.results && data.results.length > 0) {
      for (let i = 0; i < data.results.length; i++) {
        if (data?.results[i]?.features) {
          for (const item in keyValueDeepJsonify(data?.results[i]?.features)) {
            const adjustedItem = {
              item: item,
              type: "features",
            }
            const stringifiedItem = JSON.stringify(adjustedItem)
            !columnSet.has(stringifiedItem) && columnSet.add(stringifiedItem)
          }

          // adding calculated features to table columns
          for (const item in keyValueDeepJsonify(data?.results[i]?.output?.calculated_features)) {
            const adjustedItem = {
              item: item,
              type: "calculated_features",
            }
            const stringifiedItem = JSON.stringify(adjustedItem)
            !columnSet.has(stringifiedItem) && columnSet.add(stringifiedItem)
          }
        }
      }
    }

    return data?.results && data?.results?.length > 0
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Array.from(columnSet.keys()).map((column: any) => {
          const item = JSON.parse(column)
          return {
            title: item.item,
            field: item.item,
            type: item.type,
            align: "center",
          }
        })
      : []
  }, [data?.results])

  const adjustedData = useMemo((): WorkflowSimulationOutput[] => {
    return tableColumns && tableColumns.length > 0 && data?.results && data?.results.length > 0
      ? data?.results.map((item: WorkflowSimulationOutput) => {
          return { ...item, ...item.features, ...item.output?.calculated_features }
        })
      : []
  }, [data, tableColumns])

  const adjustedColumns = useMemo((): MRT_ColumnDef<WorkflowSimulationOutput>[] => {
    return [
      {
        header: "Decision",
        Header: <CustomTableHeaders title="Decision" tooltipText={"Final decision taken In this simulation run."} />,
        accessorKey: "joint_output",
        Cell: ({ row }) => {
          return (
            <Typography variant={"p"} variantColor={2}>
              {row.original?.output?.output_label ?? "__"}
            </Typography>
          )
        },
      },
      {
        header: "Reason",
        accessorKey: "reason_row",
        Header: <CustomTableHeaders title="Reason" tooltipText="Indication to the reason behind this decision." />,
        accessorFn: (row) => (decisionReason(row).text ? decisionReason(row).text : "__"),
        Cell: ({ row }) => {
          const reason = decisionReason(row.original)

          // checking if reason is not in the tag variant hash to assign a tag to it
          // else skip and render the tag directly
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !tagVariantHash[reason.text] && assignTagVariantToReasonValue(reason.text)
          return (
            <Grid container wrap="nowrap" display={"flex"}>
              {reason.type === "__" ? (
                <Typography variant={"p"} color="warning">
                  {reason.type}
                </Typography>
              ) : (
                <Tag
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  variant={tagVariantHash[reason.text] ?? "cyan"}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Tooltip title={reason.tooltip}>{reason.type}</Tooltip>
                </Tag>
              )}
            </Grid>
          )
        },
      },
      ...tableColumns.map((item): MRT_ColumnDef<WorkflowSimulationOutput> => {
        const parsedTitle = item.title.split(".")
        const type = item.type

        return {
          ...item,
          // eslint-disable-next-line
          // @ts-ignore
          accessorKey: item.title,
          header: item.title,
          Header: (
            <CustomTableHeadersWithInternalTooltip
              title={parsedTitle[parsedTitle.length - 1]}
              tooltipText={
                <>
                  Source: {item.type}
                  <br />
                  Name: {item.title}
                </>
              }
            />
          ),
          Filter: () => {
            const [value, setValue] = useState<string | number | null | undefined>()

            return (
              <InputText
                id={`${type}-${item.title}`}
                key={`${type}-${item.title}`}
                hideDescription
                placeholder={"Filter"}
                fullWidth
                value={value}
                handleChange={(e) => {
                  setValue(e.target.value)
                  debouncedFilter(
                    item.title === "KONAN_TAGS" || e.target.value.includes(",")
                      ? e.target.value.split(",").map((item) => (typeof item === "string" ? item.trim() : item)) ??
                          e.target.value
                      : e.target.value,
                    item.title,
                    type,
                  )
                }}
                endAdornment={
                  !["", null, undefined].includes(value) ? (
                    <CloseIcon
                      fontSize="small"
                      onClick={() => {
                        setValue("")
                        clearFilter(item.title, type)
                        document.getElementById(`${type}-${item.title}`).value = ""
                      }}
                    />
                  ) : undefined
                }
              />
            )
          },
          Cell: ({ row }) => {
            const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

            const value = _.get(row.original, item.title)

            return Boolean(value) && (typeof value === "object" || (Array.isArray(value) && value.length > 0)) ? (
              <Fragment>
                {Boolean(anchorEl) && (
                  <FeedbackPopper
                    id={row.original.row_id}
                    isOpen={Boolean(anchorEl)}
                    onClose={function (): void {
                      setAnchorEl(null)
                    }}
                    anchorEl={anchorEl}
                    feedback={value}
                  />
                )}

                {/* TODO:: replace with SUI anchor component in the revamp
                    leaving the styles inline to remember to remove them when revamping */}
                <Link
                  onClick={(e) => {
                    setAnchorEl(e.target)
                  }}
                  style={{
                    fontFamily: '"inter",sans-serif',
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    cursor: "pointer",
                  }}
                >
                  View
                </Link>
              </Fragment>
            ) : typeof value === "boolean" ? (
              value ? (
                <CheckIcon style={{ fontSize: "1.1rem" }} />
              ) : (
                <CloseIcon style={{ fontSize: "1.1rem" }} />
              )
            ) : (
              <Typography variant={"p"} variantColor={1}>
                {Array.isArray(value) && value.length === 0 ? "__" : ((value ?? "__") as string)}
              </Typography>
            )
          },
        }
      }),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns])

  return (
    <Fragment>
      {isExportDialogOpen && (
        <ExportDataDialog
          onClose={() => setIsExportDialogOpen(false)}
          jobType={ExportJobCreateRequest.type.SIMULATION_PREDICTIONS}
          simulation_job={uuid}
        />
      )}

      {!isLoading ? (
        <BaseTableContainer
          id="Simulation-Report-table"
          title="Simulation outputs"
          columns={adjustedColumns}
          data={adjustedData}
          source="simulation-output"
          isLoading={isFetching}
          manualPagination
          rowCount={data?.count}
          setPagination={setPagination}
          pagination={pagination}
          clearAllFiltersFn={Object.keys(filterObject).length > 0 ? () => setFilterObject({}) : undefined}
          onExportClick={() => setIsExportDialogOpen(true)}
          detailPanel={(row) => {
            return (
              <Box p={2} position={"sticky"} left={0}>
                {row.original?.output?.satisfied_flow_path ? (
                  <Grid container spacing={2}>
                    {Boolean(row.original?.output?.satisfied_flow_path) && (
                      <Grid item xs={12}>
                        <Typography variant="p" gutterBottom>
                          Workflow Path
                        </Typography>
                        <KonanJsonView src={row.original?.output?.satisfied_flow_path} />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Snackbar variant={"warning"} fullWidth>
                    No Workflow Path to show
                  </Snackbar>
                )}
              </Box>
            )
          }}
        />
      ) : (
        <Card className="card-box-shadow" style={{ height: "300px", display: "flex" }}>
          <Grid container className={styles.emptyStateRoot}>
            <Grid item xs={12} className={styles.emptyStateItem}>
              <CircularProgress size={20} color="primary" />
            </Grid>
            <Grid item xs={12} className={styles.emptyStateItem}>
              <Typography variant={"p"}>Fetching your data...</Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </Fragment>
  )
}
